﻿@media print {
    #printLogo {
        display: block !important;
    }

    .progressBar, header .page, .buttonBar {
        display: none;
    }

    .ddSummary {
        font-size: 1em;
    }

    form .requiredMessage {
        display: none;
    }

    header {
        padding-top: 0;
    }

    footer {
        & * {
            color: #000 !important;
        }

        page-break-before: always;
    }

    main.thanks > article aside #share {
        display: none;
    }

    main.thanks > article aside #donation_summary {
        text-align: left;
        padding: 0;
    }

    main.thanks section.bodyText {
        width: 100% !important;
        float: none;
    }

    .ddGuarantee {
        width: 100%;

        img.dd {
            position: relative;
            top: -1em;
        }

        ul {
            list-style-type: disc;

            li {
                padding-left: .3em;
                margin-left: 2em;
            }
        }
    }

    main {
        h1, h2, h3, h4 {
            color: #000 !important;
        }

        #thanks_img {
            display: none;
        }

        #contactPreferences {
            display: none;
        }

        .heroBox {
            padding: 0;

            h1, h2, p {
                color: #000 !important;
            }

            .ddSummary {
                p {
                    color: #000;
                }
            }
        }
    }
}
