﻿footer {
    .helpAndSecurity {
        margin-bottom: 3.5rem;

        .page, .page-large {
            @include clearfix();
            display: flex;
            flex-wrap: wrap;
        }

        article {
            float: left;
            width: calc(50% - 0.79rem);
            margin-right: 1.5rem;
            padding: 2rem;
            background-color: $background-off-white;

            &:nth-child(even) {
                margin-right: 0;
            }

            p {
                margin-bottom: 1rem;
            }
        }
    }

    .blue {
        padding-top: 3rem;
        padding-bottom: 3rem;
        background-color: $midnight;

        * {
            color: white;
        }

        .page, .page-large {
            //sets the image to the center without having to margin 0 auto it
            text-align: center;
        }

        helpAndSecurity a {
            color: white;
        }

        #regulator {
            margin-top: 2rem;
            width: 200px;
            margin-bottom: 2rem;
        }

        h5 {
            margin-bottom: 0.5rem;
            font-size: 1.1875rem;
            font-weight: 300;
        }

        .godonate {
            margin-top: 1rem;
            font-size: 1rem;
        }

        ul {
            text-align: center;

            li {
                display: inline-block;

                a {
                    display: block;
                }
            }

            &.link {
                margin-bottom: 2rem;

                li {
                    margin: 0 2rem;

                    a {
                        font-weight: 700;
                        text-decoration: none;
                        font-size: 1.1rem;
                        line-height: 1.5;

                        &:hover, &:focus {
                            text-decoration: underline;
                        }
                    }
                }
            }

            &.social {
                li {
                    margin: 0 0.25rem;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    a {
                        width: 2.7rem;
                        height: 2.7rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: white;
                        color: $midnight;
                        border-radius: 50%;

                        img {
                            //Fallback for new social link added
                            width: 45%;
                        }
                        //Size of images specific to each social element
                        &.facebook {
                            img {
                                width: 50%;
                            }
                        }

                        &.twitter {
                            img {
                                width: 52%;
                            }
                        }

                        &.youtube {
                            img {
                                width: 57%;
                            }
                        }

                        &.instagram {
                            img {
                                width: 54%;
                            }
                        }

                        &.email {
                            img {
                                width: 54%;
                            }
                        }
                    }
                }
            }
        }
    }
}


@include respond-max($mq-large) {
    footer {
        .helpAndSecurity {
            margin-bottom: 1.5rem;
            article {
                width: 100%;
                margin-right: 0;

                + article {
                    margin-top: 2rem;
                }
            }
        }

        .blue {
            ul {
                text-align: center;

                li {
                    display: inline-block;

                    a {
                        display: block;
                    }
                }

                &.link {
                    margin-bottom: 2rem;

                    li {
                        display: block;

                        + li {
                            margin-top: 0.65rem;
                        }
                    }
                }
            }
        }
    }
}

@include respond-max($mq-small) {
    footer {
        .helpAndSecurity {

            article {
                padding: 1.25rem;
            }
        }
    }
}