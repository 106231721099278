﻿.requiredMessage {
    margin-bottom: 1.6875rem;
    font-weight: 500;

    span {
        color: $error-red;
    }
}

#frmSingleDonationFormForm, #frmRegularDonationFormForm, #contactPreferences, .caption-box {
    h1 {
        margin-bottom: 1rem;
    }

    #currencyPercentage {
        font-weight: 400;
    }

    .donation-message, h4[class^="donation-message-"] {
        text-transform: none;
        font-weight: 700;

        span {
            color: $error-red;
            font-weight: 800;
        }
    }

    h4[class^="donation-message-"] {
        text-transform: uppercase;
    }

    p[class^="donation-message-amt"] + p {
        margin-top: 0;
    }

    #donationAmount {
        @include clearfix();

        h1 {
            margin-top: 0;
        }

        .input-area {
            display: none;
            margin-top: 1.625rem;

            &.active {
                display: block;
            }

            label {
                font-size: 1.375rem;
                line-height: 1.375rem;
                font-weight: 700;
                display: block;
                margin-bottom: 0.5rem;
            }

            input {
                width: 100%;
                max-width: 200px;
            }
        }
    }

    #donationType, #donationSource, #fundraising, #companyorganisation, #myDetails, #pnlAddress, #recipientAddress, #donationGift {
        p.intro {
            margin-bottom: 1rem;
        }
    }

    #giftAid {
        label {
            cursor: pointer;
        }
    }

    #myDetails, #giftDetails {
        .smaller {
            max-width: 550px;

            .input-area {
                .form-item {
                    width: 250px;
                    clear: none;

                    .validation-box {
                        width: 100%;
                    }
                }
            }
        }

        .input-area {
            .form-item {
                float: left;
                width: calc(50% - 0.75rem);
                clear: unset;

                + .form-item {
                    margin-top: 0;
                }

                &:nth-child(even) {
                    margin-right: 0;
                    margin-left: 1.5rem;
                }

                &:last-child {
                    margin-top: 1.25rem;
                    width: 75%;

                    input {
                        max-width: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }

    #donationType, #donationSource, #donationGift {
        .expand-content {
            float: left;
            width: 100%;

            fieldset {
                margin-top: 2rem;
            }

            &.no-pad {
                fieldset {
                    margin-top: 0;
                }
            }
        }
    }

    #coverFees {
        .cover-fees {
            float: left;
            width: 100%;
            margin-top: 1.5rem;

            p {
                color: $medium-grey;
                margin-bottom: 1.5rem;
            }

            label {
                background-color: $light-grey;
                @include clearfix();
                float: left;
                width: 100%;
                padding: 1.25rem;

                p {
                    margin-bottom: 0;
                    padding-top: 0;
                    color: black;
                }
            }

            a:hover {
                position: relative;
            }

            a[data-title]:hover:after {
                content: attr(data-title);
                padding: 4px 8px;
                color: #333;
                position: absolute;
                left: 0;
                bottom: 100%;
                display: inline-block;
                white-space: nowrap;
                //z-index: 20px;
                -moz-border-radius: 5px;
                -webkit-border-radius: 5px;
                border-radius: 5px;
                -moz-box-shadow: 0px 0px 4px #222;
                -webkit-box-shadow: 0px 0px 4px #222;
                box-shadow: 0px 0px 4px #222;
                background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
                background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #eeeeee),color-stop(1, #cccccc));
                background-image: -webkit-linear-gradient(top, #eeeeee, #cccccc);
                background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
                background-image: -ms-linear-gradient(top, #eeeeee, #cccccc);
                background-image: -o-linear-gradient(top, #eeeeee, #cccccc);
            }
        }
    }

    #donationSource {
        .cover-fees {
            margin-top: 1.5rem;
        }
    }

    .motivation-note {
        margin-top: 1.5rem;
        float: left;
    }

    .text-area {
        float: left;
        width: 60%;
        padding-right: 1rem;
        @include clearfix();

        .preset-values {
            @include clearfix();
            margin-top: 1.0625rem;
            margin-bottom: 2.25rem;

            button {
                float: left;
                width: calc(25% - 0.75rem);
                margin-right: 1rem;
                text-transform: uppercase;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .image {
        float: left;
        width: 40%;
        padding-left: 1rem;

        .items {
            @include clearfix();

            .item {
                display: block;

                img {
                    margin-bottom: 1.3125rem;
                }

                p {
                    font-size: 1.125rem;
                }
            }
        }
    }

    .other-type {
        font-size: 1.125rem;
    }

    .other-type.regular-link {
        margin-bottom: 1.65rem;
        display: block;
    }

    #recipientAddress {
        margin-top: 2rem;
    }

    .gift-message {
        display: block;
        width: 100%;
        float: left;
        margin-top: 1rem;
    }

    .gift-message p {
        font-size: 0.95rem;
        color: $medium-grey;
    }

    #recipientAddress, #pnlAddress, #donationType, #fundraising, #companyorganisation, #inMem, #contactPreferences, #directDebitDetails, #ajaxKeepInTouchForm {
        input, select {
            width: 320px;
        }
    }
    /*Type-ahead*/
    .tt-menu {
        background-color: #fff;
        padding: 0 !important;
        border: 1px solid #767676;

        .tt-suggestion {
            padding: .5em 10px;
            cursor: pointer;
            float: none !important;
        }

        .tt-cursor {
            background-color: #F5F5F5;
        }
    }

    #giftDetails {
        margin-top: 2rem;

        .smaller {
            .input-area {
                .form-item {
                    float: left;
                    width: 250px;
                    clear: none;

                    + .form-item {
                        margin-top: 0;
                    }

                    &:last-child {
                        width: 250px;
                        margin-top: 0;
                    }

                    @include respond-max($mq-large) {
                        width: 100%;

                        input {
                            width: 270px !important;
                        }

                        &:last-child {
                            margin-top: 1.5rem;
                            margin-left: 0;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .change-address {
        @include clearfix();
        margin-top: 2rem;

        label {
            p {
                padding-top: 0.2rem;
            }
        }
    }

    .box {
        background-color: #F5F5F5;
        float: left;
        width: 100%;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

.border-bottom {
    border-bottom: 2px solid $border-color;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
}

.borderless-bottom {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
}

.giftaid-cover, #directDebitDetails {
    h2 {
        @include clearfix();
        position: relative;
        margin-bottom: 1.175rem;

        img {
            width: 170px;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}


.giftaid-cover {
    background-position: center;
    background-size: cover;
    padding: 4rem 0;
    position: relative;
    /*&::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(white,0.2);
        z-index: 2;
    }*/

    .page {
        position: relative;
        z-index: 3;
    }

    .input-area {
        margin-top: 1.5rem;

        .item {
            background-color: $light-grey;
            padding: 1.25rem;
            @include clearfix();

            label.radio p{
                    height: unset
                
            }
        }
    }
}

#contactPreferences {
    .input-area {
        margin-top: 1.5rem;
    }

    .table {
        .options {
            @include clearfix();
            margin-bottom: 1rem;

            .filler {
                float: left;
                width: 350px;
                min-height: 1px;
            }

            p {
                float: left;
                width: 50px;
                text-align: center;
                font-weight: 300;
                text-transform: uppercase;

                + p {
                    margin-top: 0;
                }
            }
        }

        .content {
            @include clearfix();

            .item {
                @include clearfix();
                float: left;
                width: 100%;

                &.input {
                    margin-top: 0.25rem;
                    margin-bottom: 0.25rem;
                }

                + .item {
                    margin-top: 1rem;

                    &.input {
                        margin-top: 0.25rem;
                        margin-bottom: 0.25rem;
                    }
                }
            }
        }

        label.checkbox {
            float: left;
            width: 350px;
            font-weight: 400;

            strong {
                font-weight: 600;
            }

            .star {
                color: $validation-error;
                display: inline-block;
            }

            ~ .button {
                float: left;
                width: 50px;
                text-align: center;
                position: relative;

                input {
                    position: absolute;
                    top: 0;
                    left: 10px;
                    width: 1.875rem;
                    height: 1.875rem;
                    opacity: 0;
                    cursor: pointer;
                    min-width: auto;
                    padding: 0;
                    margin: 0;
                    z-index: 3;

                    &:checked {
                        ~ span {
                            &::before {
                                opacity: 1;
                            }
                        }
                    }
                }

                span {
                    width: 1.875rem;
                    height: 1.875rem;
                    display: block;
                    border: 2px solid $medium-grey;
                    border-radius: 50%;
                    margin: 0 auto;
                    cursor: pointer;

                    &::before {
                        width: 20px;
                        height: 20px;
                        display: block;
                        content: "";
                        position: absolute;
                        top: 5px;
                        left: 15px;
                        background-color: #011D49;
                        border-radius: 50%;
                        opacity: 0;
                    }
                }
            }
        }
    }
}

.keep-in-touch-thanks {
    padding: 3rem 0;
    background-color: $light-grey;

    .content {
        @include clearfix();

        .left {
            float: left;
            width: calc(62% - 2rem);
            margin-right: 2rem;
            margin-top: 2rem;
            .buttonBar{
                margin-top: 2rem;
            }
        }

        .right {
            float: left;
            width: 38%;
            padding: 2rem;
            background-color: white;
        }
    }
}

#thank-you-name{
    text-transform: capitalize;
}

@include respond-min-max($mq-medium,$mq-large) {
    #frmSingleDonationFormForm, #frmRegularDonationFormForm {

        .image {
            margin-top: 2rem;
        }
    }

    .keep-in-touch-thanks {
        padding: 2rem 0;

        .content {

            .left {
                width: 100%;
                margin-right: 0;
                margin-top: 0;
            }

            .right {
                width: 100%;
                padding: 2rem;
            }
        }
    }
}

@include respond-max($mq-medium) {
    #frmSingleDonationFormForm, #frmRegularDonationFormForm {

        .image {
            margin-top: 1.25rem;
        }

        .text-area {
            .preset-values {
                margin-bottom: 1.5rem;

                button {
                    width: calc(25% - 0.5rem);
                    margin-right: 0.6666667rem;
                    font-size: 1rem;
                    padding-left: 0;
                    padding-right: 0;
                    text-align: center;
                    overflow: hidden;
                }
            }
        }

        .other-type {
            font-size: 1rem;
        }
    }

    .giftaid-cover, #directDebitDetails {
        h2 {
            padding-right: calc(100px + 1rem);

            img {
                width: 100px;
            }
        }
    }

    .payment-btns {
        button:not(.hidden) {

            + button {
                margin-left: 0;
            }
        }

        button {
            width: 100%;
        }
    }

    #contactPreferences {
        .input-area {
            margin-top: 1.5rem;
        }

        .table {
            .options {
                float: right;

                .filler {
                    display: none;
                }
            }

            label.checkbox {
                width: calc(100% - 100px);
                padding-right: 1.25rem;
            }
        }
    }

    .keep-in-touch-thanks {
        padding: 2rem 0;

        .content {

            .left {
                width: 100%;
                margin-right: 0;
                margin-top: 0;

                .input-area {
                    max-width: 450px;
                }
            }

            .right {
                width: 100%;
                padding: 2rem;
            }
        }
    }

    #frmSingleDonationFormForm, #frmRegularDonationFormForm {
        #myDetails, #giftDetails {
            .input-area {
                .form-item {
                    width: 100% !important;

                    &:nth-child(even) {
                        margin-left: 0;
                        margin-top: 1.5rem;
                    }

                    input, select {
                        width: 270px;
                    }

                    &:last-child {
                        width: 100%;

                        input {
                            width: 400px;
                        }
                    }
                }
            }
        }

        #recipientAddress, #pnlAddress, #fundraising, #companyorganisation, #inMem, #inCeleb, #inMemPet {
            .input-area {
                input, select {
                    width: 400px;
                    max-width: 400px;
                }
            }
        }
    }
}

@include respond-max(465px) {
    #frmSingleDonationFormForm, #frmRegularDonationFormForm, #contactPreferences {
        #myDetails, #giftDetails {
            .input-area {
                .form-item {
                    &:last-child {
                        input {
                            width: 270px;
                        }
                    }
                }
            }
        }

        #recipientAddress, #pnlAddress, #fundraising, #companyorganisation, #inMem, #inCeleb, #inMem, #inMemPet, #contactPreferences, #directDebitDetails, #ajaxKeepInTouchForm {
            .input-area {
                input, select {
                    width: 270px;
                    max-width: 320px;
                }
            }
        }

        #directDebitDetails .input-area .sortCode {
            width: 270px;
        }
    }
}

@include respond-max($mq-large) {
    #frmSingleDonationFormForm, #frmRegularDonationFormForm {
        .text-area {
            width: 100%;
            padding-right: 0;

            .preset-values {
                max-width: 450px;
            }
        }

        .image {
            padding-left: 0;
            width: 100%;

            .item {
                img {
                    width: 100%;
                }
            }
        }
    }
}

/*@include respond-max(650px) {
    #frmSingleDonationFormForm, #frmRegularDonationFormForm {
        #myDetails {
            .input-area {
                .form-item {
                    &:nth-child(1), &:nth-child(2) {
                        width: 200px;
                        min-width: unset;
                    }

                    &:nth-child(3) {
                        width: 288px;
                        min-height: unset;

                        input {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    #pnlAddress {
        input, select {
            min-width: unset;
            width: 288px;
        }
    }
}*/
//288px