﻿html {
    overflow-y: scroll;
}

*{
    box-sizing: border-box;
    line-height: 1.4;
}

//FranklinGotExtCond-Regular - regular h1,h2
//FranklinGothicLTPro-Dm - demi h4 form label, button, button small
//FranklinGothicLTPro-Bk - book h3
//FranklinGothicLTPro-CnBk - book body, smaller body

@import url("https://use.typekit.net/zcm7cly.css");

@font-face {
    font-family: 'FranklinGotExtCond';
    src: url('/assets/css/webfonts/FranklinGotExtCond-Regular.eot');
    src: url('/assets/css/webfonts/FranklinGotExtCond-Regular.eot?#iefix') format('embedded-opentype'), url('/assets/css/webfonts/FranklinGotExtCond-Regular.woff') format('woff'), url('/assets/css/webfonts/FranklinGotExtCond-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

h1, .mega {
    font-size: 4rem;
    line-height: 4rem;
    font-family: 'FranklinGotExtCond';
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
}
h2 {
    font-size: 3rem;
    line-height: 3rem;
    font-family: 'FranklinGotExtCond';
    text-transform: uppercase;
    padding-bottom: 1.275rem;
}

h3 {
    font-size: 1.875rem;
    line-height: 1.875rem;
    text-transform: uppercase;
    font-weight: 400;
    padding-bottom: 1rem;
}
h4 {
    font-size: 1.375rem;
    line-height: 1.375rem;
    font-weight: 300;
    margin-bottom: 0.75rem;
}
p {
    line-height: 1.5;
    font-size: 1.1875rem;

    strong {
        font-weight: 600;
    }

    + p {
        margin-top: 1rem;
    }
}
@include respond-max($mq-medium) {

    h1, .mega {
        font-size: 3rem;
        line-height: 3rem;
    }

    h2 {
        font-size: 1.625rem;
        line-height: 1.625rem;
        padding-bottom: 0.75rem;
    }

    h3 {
        font-size: 1.3125rem;
        line-height: 1.3125rem;
    }

    h4 {
        font-size: 1rem;
        line-height: 1rem;
    }
}