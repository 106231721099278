﻿$header-image-width: 128px;
$header-image-height: calc($header-image-width / 1.52577);
$header-image-width-mobile: 160px;
$header-image-height-mobile: calc(80px / 1.52577);
$header-image-width-mobile-x-small: 120px;
$header-image-height-mobile-x-small: calc(60px / 1.52577);

header {
    padding: 2rem 0;
    border-bottom: 1px solid $border-color;
    //position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999;
    background-color: white;

    .page, .page-large {
        @include clearfix();
    }

    a {
        display: block;
        float: left;

        #siteLogo {
            //background-image: url(/assets/images/logo.png);
            background-size: contain;
            background-repeat: no-repeat;
            width: $header-image-width;
            height: $header-image-height;
            background-color: white;
        }

        #siteLogoMobile {
            background-size: contain;
            background-repeat: no-repeat;
            width: $header-image-width;
            height: $header-image-height;
            background-position: center;
            display: none;
        }
    }

    .right {
        float: right;
        height: $header-image-height;
        display: flex;
        align-items: center;
        font-weight: 700;

        img.pad {
            margin-right: 0.75rem;
            padding-bottom: 6px;
        }

        img.dd-image {
            margin-left: 2rem;
            max-height: 100%;
            max-width: 100%;
        }
    }

    &.hide {
        display: none
    }
}

//Override for main here as this is all dependend on teh header

main {
    padding-top: 2rem;
    float: left;
    width: 100%;
    &.no-padded-top{
        padding-top: 0;
    }
}

@include respond-min-max($mq-medium,$mq-large) {
    header {
        padding: 0.5rem 0;
    }

    main {
        &.no-padded-top {
            padding-top: 0;
        }
    }
}

@include respond-max($mq-medium) {
    header {
        padding: 0.5rem 0;

        a {
            #siteLogo {
                display: none;
            }

            #siteLogoMobile {
                display: block;
                width: $header-image-width-mobile;
                height: $header-image-height-mobile;
            }
        }

        .right {
            height: $header-image-height-mobile;

            img.dd-image {
                width: 75px;
            }
        }
    }
}

@include respond-max(500px) {
    header {

        a {
            #siteLogoMobile {
                width: $header-image-width-mobile-x-small;
                height: $header-image-height-mobile-x-small;
            }
        }

        .right {
            height: $header-image-height-mobile-x-small;

            p {
                font-size: 0.95rem;
            }

            img.pad {
                width: 1.5rem;
                padding-bottom: 0;
                margin-right: 0.25rem;
            }

            img.dd-image {
                margin-left: 0;
                width: 50px;
                margin-left: 0.75rem;
            }
        }
    }

    main {
        &.no-padded-top {
            padding-top: 0px;
        }
    }
}