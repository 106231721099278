﻿@mixin requiredAsterisk() {
    &::after {
        content: '\00a0*';
        font-size: 1.3rem;
        font-weight: bold;
        vertical-align: middle;
        color: $requiredColour;
    }
}

@mixin validTick() {
    content: '';
    background: transparent url(../images/validTick.png) no-repeat 0 0;
    display: inline-block;
    width: 11px !important; //validation js tries to override if error
    height: 16px;
    margin-left: 4px;
    margin-right: -13px;
    position: relative;
    top: 4px;
}

@mixin invalidCross() {
    content: '';
    background: transparent url(../images/invalidCross.png) no-repeat 0 0;
    display: inline-block;
    width: 15px;
    height: 16px;
    margin-left: 4px;
    position: absolute;
    top: -25px;
    right: -23px;
}

@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}

@mixin respond-min( $width ) {
    @media only screen and ( min-width: $width ) {
        @content;
    }
}

@mixin respond-max( $width ) {
    @media only screen and ( max-width: $width - 1px) {
        @content;
    }
}

@mixin respond-min-max( $min, $max ) {
    @media only screen and ( min-width: $min ) and ( max-width: $max - 1px ) {
        @content;
    }
}