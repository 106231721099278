﻿$donationSummaryHeadingHeight: 58px;

.donationSummaryTable {
    .smaller-with-content {
        position: relative;
        @include clearfix();

        h3 {
            float: left;
            width: calc(100% - 340px);
            height: $donationSummaryHeadingHeight;
            display: flex;
            align-items: center;
            padding: 0;
        }

        a.amend {
            float: left;
            display: block;
            padding: 1.25rem 1rem 1rem 1rem;
            font-weight: 600;
            background-color: $bright-blue;
            color: black;
            text-decoration: none;
            height: $donationSummaryHeadingHeight;
        }

        .image {
            float: right;
            height: $donationSummaryHeadingHeight;
            width: 125px;
            display: flex;
            align-items: center;

            img {
                width: 100%;
            }
        }

        .table {
            float: left;
            width: calc(100% - 189px);
            margin-top: 2rem;
        }
    }
}

#privacy {
    .table {
        margin-top: 1.5rem;

        ul {
            margin-bottom: 1rem;

            li {
                font-size: 1.1rem;
                @include clearfix();

                + li {
                    margin-top: 0.5rem;
                }

                strong {
                    font-weight: bold;
                    float: left;
                    width: 100px;
                }

                p {
                    float: left;
                    width: calc(100% - 100px);
                    padding-left: 1rem;
                }


                @include respond-min($mq-medium) {
                    a#contact-phone {
                        text-decoration: none;
                    }
                }

                a#contact-address {
                    text-decoration: none;
                }
            }
        }
    }
}

    .ddInfo {
        ul {
            margin-top: 1rem;
            margin-bottom: 1rem;
            padding-left: 1.5rem;

            li {
                font-size: 1.1rem;
                list-style-type: disc;

                + li {
                    margin-top: 0.5rem;
                }
            }
        }
    }

    #directDebitDetails {
        ul {
            margin-top: 1rem;
            margin-bottom: 1rem;
            padding-left: 1.5rem;

            li {
                font-size: 1.1rem;
                list-style-type: disc;

                + li {
                    margin-top: 0.5rem;
                }
            }
        }

        .input-area {
            margin-top: 1.5rem;

            .help {
                float: left;
                width: 100%;
                margin-top: 1rem;
            }

            .form-item {
                width: 100%;
            }

            .sortCode {
                @include clearfix();
                max-width: 370px;

                .validation-box {
                    float: left;
                    width: calc(33.333333% - 0.7rem) !important;
                    margin-right: 1rem;
                    clear: none;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                input {
                    width: 100% !important;
                }
            }
        }
    }

    @include respond-max($mq-medium) {

        #privacy {
            .table {

                ul {
                    li {

                        strong {
                            width: 100%;
                            margin-bottom: 0.25rem;
                        }

                        p {
                            width: 100%;
                            padding-left: 0;
                        }
                    }
                }
            }
        }

        #directDebitDetails {

            .input-area {

                .sortCode {
                    max-width: 320px;
                }
            }
        }
    }

    @include respond-max($mq-large) {

        .donationSummaryTable {
            .smaller-with-content {

                h3 {
                    width: calc(100% - 270px);
                }

                .table {
                    width: 100%;
                    margin-top: 2rem;
                }
            }
        }
    }

    .regularSummaryButtons {
        .smaller {
            @include clearfix();
            display: flex;
            align-items: center;
        }

        button {
            float: left;
            margin-right: 1rem;
        }

        p {
            float: left;
        }
    }


    .motivation-note {
        margin-top: 1.5rem;
        float: left;
    }

    @include respond-max($mq-medium) {
        .donationSummaryTable {
            .smaller-with-content {

                h3 {
                    width: calc(100% - 125px);
                    margin-bottom: 58px;
                }

                a.amend {
                    position: absolute;
                    top: 58px;
                    left: 0;
                    width: 100%;
                    text-align: center;
                }

                .image {
                    width: 100px;
                }

                .table {
                    width: 100%;
                    margin-top: 2rem;
                }
            }
        }

        .regularSummaryButtons {

            .smaller {
                display: block;
            }

            button {
                margin-right: 0;
                width: 100%;
                margin-bottom: 1rem;
            }

            p {
                float: left;
            }
        }
    }