﻿body, input, textarea, select, * {
    font-family: franklin-gothic-urw, sans-serif;
    font-weight: 400;
    font-style: normal;
}

select::-ms-expand {
    display: none;
}

.field-validation-error, .validation-plus, .no-change {
    color: $validation-error;
    display: block;
    margin-top: 0.5rem;
    float: left;
    width: 100%;

    &.hidden {
        display: none;
    }
}

.field-validation-error ~ .validation-plus{
    display: none !important;
}

#card-number, #expiration-date, #cvv {
    border: 2px solid $medium-grey;
    border-radius: 4px;
    padding: 0.4rem 0.5rem;
    height: 43px;
    float: left;
}

#cvv {
    float: left;

    ~ a {
        float: left;
        margin-left: 1rem;
        width: calc(40px - 1rem);
        height: calc(40px - 1rem);
        background-color: $medium-grey;
        border-radius: 50%;
        overflow: hidden;
        margin-top: 9px;
        color: white;
        font-weight: 600;
        text-decoration: none;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding-top: 1px;
        }

        .content {
            opacity: 0;
            visibility: hidden;
            transition: $ease $speed opacity, $ease $speed visibility;
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 9999999;
            background-color: rgba(black,0.3);

            .inner {
                max-width: 475px;
                display: block;
                margin: 0 auto;
                background: white;
                padding: 2rem;
                cursor: default;

                * {
                    color: black;
                }

                p {
                    font-weight: 500;
                }

                div {
                    margin-top: 1.5rem;
                    background: $midnight;
                    display: table;
                    padding: 0.9rem 1.5rem 0.75rem;
                    text-transform: uppercase;
                    color: white;
                    cursor: pointer;
                }
            }
        }

        &.active {
            .content {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

#paymentDetails {
    #ccDetails {
        .form-group {
            @include clearfix();

            + .form-group {
                margin-top: 1rem;
            }

            label {
                font-weight: 600;
            }

            .acceptedCards {
                float: right;
                width: 50%;
                padding-left: 3rem;
                height: 42px;
                display: flex;
                align-items: center;

                p {
                    font-weight: 400;
                    margin-right: 0.75rem;
                }

                img {
                    float: left;
                    width: 40px;

                    + img {
                        margin-left: 0.5rem;
                    }
                }
            }

            #card-number {
                float: left;
                width: 50%;
                padding-right: 0.75rem;

                #ccAddon {
                    position: absolute;
                    right: 40px;
                    top: 8px;
                }
            }
        }
    }
}

.acceptedCards{
    @include clearfix();
    p{
        float: left;
    }
}

input[type="submit"].smaller, button.smaller {
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 300;
}

input[type="submit"], button {
    padding: 1.25rem 1rem;
    background-color: $light-grey;
    border: none;
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.25rem;
    font-weight: 700;

    &:hover {
        background-color: $button-hover;
        color: black;
    }

    &:focus, &.active {
        background-color: $midnight;
        color: white;
    }
}

label.checkbox {
    @include clearfix();
    font-weight: 500;
    font-size: 1.1rem;
    input {
        display: none;

        &:checked {
            opacity: 0.5;

            ~ span {
                &::before {
                    opacity: 1;
                }
            }
        }
    }

    span {
        width: 1.875rem;
        height: 1.875rem;
        border: 2px solid $medium-grey;
        border-radius: 4px;
        display: block;
        position: relative;
        float: left;
        margin-right: 1rem;

        &::before {
            content: "¬";
            transform: rotate(132deg);
            display: block;
            font-size: 36px;
            width: 0px;
            height: 0px;
            color: $midnight;
            position: relative;
            top: 26px;
            left: 44px;
            opacity: 0;
            border-radius: 4px;
        }
    }

    p {
        float: left;
        width: calc(100% - 2.875rem);
        padding-top: 0.4rem;
        font-weight: 400;
    }
}

.input-area {
    @include clearfix();
}

.on-behalf-of, #on-behalf-of-input {
    margin-top: 1.5rem;
}

#onBehalfOfDetails {   
    .smaller {
        .input-area {
            .form-item, .form-item:last-child {
                margin-top: 1.5rem !important;
            }
        }
    }
}

label.radio {
    @include clearfix();
    float: left;
    width: 100%;
    cursor: pointer;
    font-weight: 500;
    position: relative;

    + label.radio {
        margin-top: 1rem;
    }

    input {
        position: absolute;
        display: none;

        &:checked {
            ~ span {
                &::before {
                    opacity: 1;
                }
            }
        }
    }

    span {
        display: block;
        border: 2px solid $medium-grey;
        width: 1.875rem;
        height: 1.875rem;
        float: left;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;

        &::before {
            width: 20px;
            height: 20px;
            display: block;
            content: "";
            position: absolute;
            top: 3px;
            left: 3px;
            background-color: $midnight;
            border-radius: 50%;
            opacity: 0;
        }

        @include respond-max($mq-small){
            &::before{
                top: 5px;
                left: 5px;
            }
        }
    }

    p {
        float: left;
        width: calc(100% - 2.875rem);
        height: 1.875rem;
        display: flex;
        align-items: center;
        padding-left: 2.5rem;
        padding-top: 0.3rem;
    }
}

input {
    border: 2px solid $medium-grey;
    border-radius: 4px;
    padding: 0.6rem 2rem 0.6rem 0.5rem;
    line-height: 1.5;
    font-size: 1.1rem;
}

input,select,textarea{
    max-width: 100%;
    width: 100%;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input-validation-error-override {
}


//validation inputs
input.valid,
#card-number.card-valid, 
#cvv.card-valid,
#expiration-date.card-valid, 
select.valid{
    border-color: $success-green;
    background-color: rgba($success-green,0.075);
}


input.input-validation-error-override {
    border-color: $error-red !important;
    background-color: rgba($error-red,0.075) !important;
}


#card-number.card-valid,
#cvv.card-valid,
#expiration-date.card-valid {
    position: relative;

    &::before, &::after {
        content: "";
        position: absolute;
        display: block;
        height: 4px;
        background: $success-green;
    }

    &::before {
        transform: rotate(-45deg);
        right: 10px;
        width: 15px;
        top: 17px;
    }

    &::after {
        width: 8px;
        right: 20px;
        transform: rotate(45deg);
        top: 19px;
    }
}

//validation in inputs
input.input-validation-error,
#card-number.card-validation-error,
#cvv.card-validation-error,
#expiration-date.card-validation-error,
select.input-validation-error {
    border-color: $error-red;
    background-color: rgba($error-red,0.075) !important;
}

.validation-box {
    position: relative;
    display: block;
    float: left;
    clear: both;

    input.input-validation-error-override {
        ~ .validation-icon {
            &::before, &::after {
                content: "" !important;
                position: absolute !important;
                display: block !important;
                height: 4px !important;
                width: 15px !important;
                background: $error-red !important;
                top: 8px !important;
                right: 0 !important;
            }

            &::before {
                transform: rotate(-45deg) !important;
            }

            &::after {
                transform: rotate(45deg) !important;
            }
        }
    }

    input.input-validation-error,
    textarea.input-validation-error,
    select.input-validation-error,
    input.input-validation-error.valid,
    textarea.input-validation-error.valid,
    select.input-validation-error.valid {
        ~ .validation-icon {
            &::before, &::after {
                content: "";
                position: absolute;
                display: block;
                height: 4px;
                width: 15px;
                background: $error-red;
                top: 8px;
                right: 0;
            }

            &::before {
                transform: rotate(-45deg);
            }

            &::after {
                transform: rotate(45deg);
            }
        }
    }

    input.valid, textarea.valid, select.valid {
        ~ .validation-icon {
            &::before, &::after {
                content: "";
                position: absolute;
                display: block;
                height: 4px;
                background: $success-green;
            }

            &::before {
                transform: rotate(-45deg);
                right: 0;
                width: 15px;
                top: 7px;
            }

            &::after {
                width: 8px;
                right: 10px;
                transform: rotate(45deg);
                top: 10px;
            }
        }
    }

    select.valid {
        background-size: 0px;
    }

    .validation-icon {
        position: absolute;
        top: calc(50% - 10px);
        right: 10px;
        width: 15px;
        height: 15px;
        display: block;
        pointer-events: none;
    }
}

#card-number.card-validation-error,
#cvv.card-validation-error,
#expiration-date.card-validation-error {
    position: relative;

    &::before, &::after {
        content: "";
        position: absolute;
        display: block;
        height: 4px;
        width: 15px;
        background: $error-red;
        top: 17px;
        right: 10px;
    }

    &::before {
        transform: rotate(-45deg);
    }

    &::after {
        transform: rotate(45deg);
    }
}


@include respond-max($mq-medium) {
    #paymentDetails {
        #ccDetails {
            .form-group-cc {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                float: left;
                width: 100%;

                label {
                    order: 0;
                }

                #card-number {
                    order: 1;
                    width: 270px;

                    #ccAddon {
                        position: absolute;
                        right: 40px;
                        top: 8px;
                    }
                }

                span.field-validation-error {
                    order: 2;
                }

                .acceptedCards {
                    order: 3;
                    padding-left: 0;
                    width: 100%;
                }
            }
        }
    }

}

select {
    border: 2px solid $medium-grey;
    border-radius: 4px;
    padding: 0.6rem 2.5rem 0.6rem 0.5rem; 
    line-height: 1.5;
    font-size: 1.1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(/assets/images/dropdown-arrow.png);
    background-repeat: no-repeat;
    background-position: calc(100% - 0.5rem) 50%;
    background-size: 15px;
    width: 100%;

    option {
        padding: 5px !important;
    }
}

.fieldHelp{
    margin-top: 0.5rem;
    display: block;
    float: left;
    width: 100%;
}

.form-item {
    float: left;
    clear: both;
    + .form-item {
        margin-top: 1.25rem;
    }
}

#on-behalf-of-input {
    .form-item {
        margin-top: 1.25rem;
    }
}

label {
    display: block;
    font-weight: 600;
    margin-bottom: 0.3rem;
    float: left;
    width: 100%;

    span {
        color: $validation-error;
        font-weight: 600;
    }
}

#paymentMethodSelector {
    #btnPP {
        background-color: $paypal-blue;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        padding: 1rem 2rem;
        border-radius: 4px;

        img {
            &.small {
                margin-right: 0.5rem;
                height: 1.1rem;
            }

            &.large {
                height: 1.3rem;
            }
        }
    }
    /* old styling - hardcoded button */
    /*  #btnGooglePay {
        background-color: black;
        color: white;
        border-radius: 4px;
        padding: 1rem 1.5rem;
        font-weight: 300;

        img {
            display: inline-block;
            width: 1.1rem;
            position: relative;
            top: 2px;
            margin: 0 2px;
        }
    }*/
    #btnGooglePay {
        button.gpay-button {
            width: 160px;
        }

        @media only screen and (max-width: 766px) {
            button.gpay-button {
                width:100%
            }
        }
    }

    #btnApplePay {
        background-color: black;
        color: white;
        border-radius: 4px;
        padding: 1rem 1.5rem;
        font-weight: 300;

        img {
            display: inline-block;
            width: 1.1rem;
            position: relative;
            top: 2px;
            margin: 0 2px;
        }
    }

    #btnCC {
        background-color: $midnight;
        color: white;
        position: relative;
        padding: 1rem 1.5rem;
        font-weight: 600;

        img {
            display: inline-block;
            width: 1.7rem;
            margin-right: 1rem;
            position: relative;
            top: 2px;
        }
    }
}

.payment-btns {
    @include clearfix();
    margin-top: 1.5rem;

    button:not(.hidden) {
        float: left;
    }

    button {
        margin-right: 1.5rem;
        margin-bottom: 10px;
        height: 53px;
    }
}

.buttonBar {
    margin-bottom: 3.5rem;

    #btnSubmit, #btnContactPreferencesSubmit {
        background-color: $orange;
        text-transform: uppercase;
        padding: 1.25rem 4.4rem 1.25rem 1.5rem;
        font-weight: 600;
        @include clearfix();
        position: relative;
        text-align: left;
        color: $black;

        img {
            width: 1.4rem;
            position: absolute;
            top: calc(50% - 8px);
            right: 1.5rem;
        }
    }
}

#expiration-date {
    width: 115px;
}

#cvv {
    width: 80px;
}

@include respond-max($mq-medium) {
    .buttonBar {
        margin-bottom: 1.5rem;
    }
}

@include respond-max($mq-small) {
    label.radio {
        span {
            position: static;
        }

        p {
            padding-left: 0.75rem;
            min-height: 1.875rem;
            height: auto;
        }
    }
}

.ErrorMessage {
    .page {
        background-color: rgba($error-red, 0.3);
        border: 2px solid $error-red;
        padding: 2rem;
    }

    ul {
        padding-left: 1.5rem;
        margin-top: 1rem;

        li {
            line-height: 1.5;
            font-size: 1.1rem;
            list-style-type: disc;

            + li {
                margin-top: 0.25rem;
            }
        }
    }
}

form {
    #giftAddressAutoEntry {
        height: auto;
        &.hidden{
            display: block;
            opacity: 0;
            visibility: hidden;
            height: 0;
            overflow: hidden;
        }
    }
}

.spinner {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 1;
    background: rgba(255,255,255,0.5);
    background: -webkit-radial-gradient(rgba(0,0,0,0.6) 1%, rgba(0,0,0,0.8));
    background: -moz-radial-gradient(rgba(0,0,0,0.6) 1%, rgba(0,0,0,0.8));
    background: -ms-radial-gradient(rgba(0,0,0,0.6) 1%, rgba(0,0,0,0.8));
    background: radial-gradient(rgba(0,0,0,0.6) 1%, rgba(0,0,0,0.8));
    z-index: 9999;

    &.active {
        display: block;
    }

    .image {
        width: 100%;
        height: 100%;
        background-image: url('/assets/images/loader.gif');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 165px 165px;
    }

    h3 {
        display: block;
        position: absolute;
        top: 50%;
        width: 100%;
        text-align: center;
        margin-top: 100px;
        color: $white;
    }
}
