﻿.top-section {
    float: left;
    width: 100%;
}

.sub-heading {
    float: left;
    width: 100%;
}

.wide-boxes {
    float: left;
    width: 100%;
}

.cover-image {
    float: left;
    width: 100%;
}

.other-ways-to-help {
    float: left;
    width: 100%;
}

footer {
    float: left;
    width: 100%;
}



.top-section {
    //padding-top: 3.125rem;
    padding-bottom: 3.125rem;
    background-size: cover;
    background-position: center;

    .page-large {
        @include clearfix();
    }

    .content {
        background-color: white;
        padding: 3rem;
        float: left;
        width: 50%;
        @include clearfix();

        .bottom {
            @include clearfix();
            float: left;
            width: 100%;

            button, a {
                float: left;
                width: 50%;
                background-color: $orange;
                text-transform: uppercase;
                padding: 1.4rem 2rem 1.4rem 0;
                text-decoration: none;
                cursor: pointer;
                font-size: 1.25rem;
                line-height: 1.25rem;
                color: black;
                position: relative;
                text-align: center;
                font-weight: 700;

                img {
                    width: 1.25rem;
                    position: absolute;
                    right: 1rem;
                    top: calc(50% - 6px);
                }
            }

            .payment-deets {
                float: right;
                width: 50%;
                padding-left: 1.5rem;
                text-align: right;

                &.regular-donation-fields {
                    ul {
                        align-items: center;
                        height: 100%;
                    }

                    img {
                        width: 100%;
                        max-width: 90px;
                    }
                }

                ul {
                    @include clearfix();
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    max-width: 152px;
                    float: right;

                    li {
                        float: left;
                        @include clearfix();
                        margin-left: 10px;

                        &:nth-child(n+4) {
                            margin-top: 10px;
                        }

                        img {
                            float: left;
                        }
                    }
                }
            }
        }

        p.intro {
            margin-bottom: 1rem;
        }

        .buttons {
            @include clearfix();
            float: left;
            width: 100%;
            background-color: $light-grey;
            padding: 0.625rem;
            //margin-bottom: 1.5rem;
            display: flex;
            text-align: center;

            button {
                float: left;
                width: 50%;
                text-transform: uppercase;
                padding: 1rem;
                font-size: 1.25rem;
                padding: 1.4rem 0;
            }
        }

        .buttons-tab {
           margin-bottom: 1.5rem;
        }

        .buttons-orange {
            background-color: transparent;
            padding: 0;



            a {
                display: block;
                background-color: $orange;
                color: $black;
                float: left;
                width: 50%;
                text-transform: uppercase;
                font-size: 1.25rem;
                padding: 1.4rem 0;
                line-height: 1.25rem;
                font-weight: 700;
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }
            }

            @include respond-max($mq-medium) {
                display: grid;

                a {
                    margin: 0.3em 0;
                    width: 100%;
                }

                .order-1 {
                    grid-row: 1;
                }

                .order-2 {
                    grid-row: 2;
                }
            }

            #btn-regular {
                margin-right: 1rem;
            }
        }

        .buttons-orange-heading {
            @include respond-max($mq-medium) {
                display: none;
            }
        }

        .amount-buttons {
            @include clearfix();
            float: left;
            width: 100%;
            margin-bottom: 1.5rem;

            button {
                float: left;
                width: calc(25% - 0.75rem);
                margin-right: 1rem;
                text-transform: uppercase;
                padding-left: 0;
                padding-right: 0;

                &:nth-child(4n+4) {
                    margin-right: 0;
                }
            }
        }

        .box {
            background-color: $light-grey;
            float: left;
            width: 100%;
            padding: 1.5rem;
            margin-bottom: 1.5rem;

            label {
                font-size: 1.2rem;
                font-weight: 700;
            }

            input {
                width: 170px;
                min-width: unset;
            }


            h3 {
                font-weight: 300;
                font-size: 1.25rem;
                padding-bottom: 0.5rem;
            }

            .other-amount {

                input {
                    border-left: none;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    float: left;
                    font-size: 19px;
                    padding-bottom: 8px;
                    padding-left: 15px;
                }

                span.currency-box {
                    float: left;
                    display: inline-block;
                    width: 50px;
                    background-color: #011D49;
                    color: #FFFFFF;
                    text-align: center;
                    vertical-align: middle;
                    font-size: 30px;
                    font-family: franklin-gothic-urw, sans-serif;
                    font-weight: 700;
                    height: 49px;
                    border: 4px solid #011D49;
                    border-bottom: 4px solid #011D49;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }
        }

        .social {
            margin-top: 2rem;

            ul {
                @include clearfix();

                li {
                    width: calc(50% - 0.5rem);
                    float: left;
                    background-color: $light-grey;
                    text-align: center;
                    font-weight: 300;
                    font-size: 1.15rem;

                    a {
                        color: white;
                        text-decoration: none;
                        display: block;
                        padding: 1rem 0.3rem 0.8rem 0.3rem;
                        font-weight: 600;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: row;
                        font-size: 1rem;

                        img {
                            filter: brightness(0) invert(100);
                            display: inline-block;
                            margin-right: 0.5rem;
                            position: relative;
                        }
                    }

                    &:nth-child(odd) {
                        margin-right: 1rem;
                    }

                    &.facebook {
                        background-color: $facebook;

                        img {
                            width: 1.3rem;
                        }
                    }

                    &.twitter {
                        background-color: $twitter;

                        img {
                            width: 1.5rem;
                        }
                    }
                }
            }
        }

        .right {
            float: right;
            height: 83.8920676118px;
            display: flex;
            align-items: center;
            font-weight: 700;
        }

        .padlock-hide {
            @include respond-max($mq-medium) {
                display: none;
            }
        }
    }
}

.help-us-do-even-more, .sticky-banner {
    background-color: $white;

    .buttons-orange {
        float: left;
        width: 100%;
        margin-bottom: 1.5rem;
        display: flex;
        text-align: center;
        background-color: transparent;
        padding: 0;

        a {
            background-color: $orange;
            color: $black;
            width: 100%;
            text-transform: uppercase;

            float: left;
            text-transform: uppercase;
            font-size: 1.25rem;
            padding: 1.4rem 0;
            line-height: 1.25rem;
            font-weight: 700;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }

        #btn-regular {
            margin-right: 1rem;
        }
    }
}

.help-us-do-even-more {
    padding-bottom: 1rem;

    @include respond-max($mq-medium) {
        padding-bottom: 2rem;
    }

    .buttons-orange {
        @include respond-max($mq-medium) {
            display: block;

            a {
                margin: 0.3em 0;
            }
        }
    }
}

.sticky-banner {
    padding-top: 1rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    transition: ease-in-out 0.1s;
    display: table;


    &.sticky-banner2 {
        position: relative;
        height: 200px;
        display: table;
    }
}

table {
    background-color: $background-off-white;
    width: 100%;

    tr {
        + tr {
            border-top: 1px solid $border-color;
        }

        td {
            padding: 1rem;

            &:nth-child(2) {
                font-weight: 600;
            }
        }
    }
}

@include respond-max(1020px){
    .top-section{
        .content{
            width: 62%;
        }
    }
}

.sub-heading{
    text-align: center;
    padding: 0 0 5rem 0;
    h2{
        padding-bottom: 0;
    }
}

.wide-boxes {
    margin-bottom: 5rem;
    .box {
        background-color: $bright-blue;
        @include clearfix();
        position: relative;

        + .box {
            margin-top: 2rem;
        }

        .content {
            float: left;
            width: 50%;
            padding: 2rem;
        }

        .image {
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
            height: 100%;
            background-position: center;
            background-size: cover;
        }

        &:nth-child(odd) {
            .image {
                left: 0;
            }
            .content{
                margin-left: 50%;
            }
        }
    }
}

.cover-image{
    margin-bottom: 5rem;
    padding-top: 3rem;
    padding-bottom: 15rem;
    background-color: $light-grey;
    text-align: center;
    background-size: cover;
    background-position: center;
}

.mobile-image{
    display: none;
    height: 0;
    padding-top: 50%;
    background-size: cover;
    background-position: center;
    float: left;
    width: 100%;
    margin-bottom:15px;
}

.our-impact {
    h2 {
        text-align: center;
        margin-bottom: 2rem;
        padding-bottom: 0;
    }

    p.body-text {
        margin-bottom: 2rem;
    }

    .boxes {
        @include clearfix();
        display: flex;
        flex-wrap: wrap;

        .box {
            float: left;
            width: calc(33.333333% - 1.05rem);
            margin-right: 1.5rem;
            text-align: center;
            @include clearfix();
            position: relative;

            img {
                float: left;
                width: 100%;
            }

            &:nth-child(3n+3) {
                margin-right: 0;
            }

            .inner {
                float: left;
                width: 100%;
                padding: 1rem;
                margin-bottom: 3.25rem;
            }

            h3 {
                text-transform: uppercase;
                font-weight: 700;
                font-size: 1.375rem;
            }

            p {
                font-size: 15px;
                font-family: franklin-gothic-urw, sans-serif;
            }
        }
    }
}

    .other-ways-to-help {
        margin-bottom: 5rem;
        margin-top: 3rem;

        h2 {
            text-align: center;
            margin-bottom: 2rem;
        }

        .boxes {
            @include clearfix();
            display: flex;
            flex-wrap: wrap;

            .box {
                float: left;
                width: calc(33.333333% - 1.05rem);
                margin-right: 1.5rem;
                text-align: center;
                @include clearfix();
                position: relative;

                img {
                    float: left;
                    width: 100%;
                }

                &:nth-child(3n+3) {
                    margin-right: 0;
                }

                .inner {
                    float: left;
                    width: 100%;
                    padding: 1rem;
                    margin-bottom: 3.25rem;
                }

                h3 {
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 1.375rem;
                }

                p {
                    font-size: 15px;
                    font-family: franklin-gothic-urw, sans-serif;
                }

                a {
                    background-color: $bright-blue;
                    display: block;
                    width: 100%;
                    font-weight: 700;
                    padding: 1rem 3rem 1rem 1rem;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    text-decoration: none;
                    text-align: left;

                    img {
                        width: 1.25rem;
                        position: absolute;
                        right: 1rem;
                        top: calc(50% - 6px);
                    }
                }
            }
        }
    }

    body.appeal {
        footer {
            .helpAndSecurity {
                display: none;
            }
        }
    }

    @include respond-min($mq-large) {
        .bottom {
            .payment-deets {

                &.regular-donation-fields {
                    img {
                        padding-top: 18px;
                    }
                }
            }
        }
    }

    @include respond-max($mq-large) {
        .top-section {
            background-image: url() !important;

            .content {
                width: 100%;
                padding: 0;

                .bottom {
                    display: flex;

                    button, a {
                        width: 250px;
                    }

                    .payment-deets {
                        width: calc(100% - 250px);

                        ul {
                            max-width: 100%;
                            display: flex;
                            align-items: center;
                            height: 100%;

                            li {
                                margin-left: 10px;

                                &:nth-child(n+4) {
                                    margin-top: 0;
                                }

                                &:first-child {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .wide-boxes {
            .box {
                display: flex;
                flex-wrap: wrap;

                .image {
                    width: 100%;
                    height: 0;
                    padding-top: 70%;
                    position: static;
                    float: none;
                    order: 1;
                }

                .content {
                    width: 100%;
                    background-color: $bright-blue;
                    float: none;
                    order: 2;
                }

                &:nth-child(odd) {

                    .content {
                        margin-left: 0;
                    }
                }
            }
        }

        .mobile-image {
            display: block;
        }
    }

    @include respond-max(625px) {
        .top-section {

            .content {

                .bottom {

                    .payment-deets {

                        ul {
                            li {
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }
    }


    @include respond-max($mq-medium) {
        .top-section {
            .content {
                .bottom {
                    button, a {
                        width: 250px;
                    }

                    .payment-deets {
                        width: calc(100% - 250px);
                    }
                }

                .social {

                    ul {

                        li {
                            width: 100%;

                            &:nth-child(odd) {
                                margin-right: 0;
                                margin-bottom: 1rem;
                            }
                        }
                    }
                }
            }
        }

        .wide-boxes {
            .box {
                .image {
                    padding-top: 50%;
                }

                .content {
                    text-align: center;
                    padding: 1.5rem;
                }
            }
        }

        .sub-heading {
            padding: 2rem 0 0 0;
        }

        .wide-boxes, .cover-image {
            margin-top: 2rem;
        }

        .cover-image {
            margin-bottom: 2rem;
        }

        .other-ways-to-help, .our-impact {
            &:not(.our-impact) {
                margin-bottom: 3rem;
                margin-top: 0;
            }

            .boxes {
                .box {
                    width: 100%;
                    margin-right: 0;

                    + .box {
                        margin-top: 3rem;
                    }
                }
            }
        }
    }

    @include respond-max(600px) {
        .top-section {
            .content {
                .bottom {
                    display: block;

                    button, a {
                        width: 100%;
                        padding: 1.3rem 2rem 1.3rem 2rem;
                        text-align: left;
                    }

                    .payment-deets {
                        width: 100%;
                        margin-top: 1rem;
                        padding-left: 0;

                        ul {
                            text-align: center;
                            width: 100%;
                            justify-content: center;

                            li {
                                float: none;
                                display: inline-block;
                                margin: 0 5px;

                                &:last-child {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include respond-max($mq-small) {
        .mobile-image {
            padding-top: 75%;
        }

        .top-section {
            .content {
                .bottom {
                    .payment-deets {
                        ul {
                            li {
                                img {
                                    width: 32px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    section.banner-appeal {
        background-color: #F5F5F5;
        padding: 1.25rem;
        max-width: 124rem;

        @include respond-min($mq-large) {
            padding: 2rem;
        }

        @include respond-min($mq-x-large) {
            max-width: 100%;
        }

        .content-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            width: 100%;

            @include respond-min($mq-large) {
                flex-direction: row;
                column-gap: 3em;
                //width: 100%;
            }

            @include respond-min($mq-x-large) {
                flex-direction: row;
                width: 70%;
            }

            .text-wrap {
                display: flex;
                flex-direction: column;
                flex: 100%;

                @include respond-min($mq-medium) {
                    flex: 1 1 50%;
                    max-width: 30rem;
                    margin: auto;
                }

                @include respond-min($mq-large) {
                    flex-direction: column;
                    flex: 1 50%;
                    //min-width: 30rem;
                }

                @include respond-min($mq-x-large) {
                    /*                display: flex;
                flex-direction: column;            
*/
                }

                h1 {
                    margin-top: 0;
                }

                a {
                    display: flex;
                    float: left;
                    color: #000000;
                    background-color: $orange;
                    border-color: $orange;
                    border-radius: 0;
                    font-size: 1.25rem;
                    line-height: 1.125rem;
                    font-weight: 700;
                    text-align: left;
                    text-transform: uppercase;
                    text-decoration: none;
                    width: 100%;
                    padding: 1.5rem;
                    margin-top: 1.5rem;
                    box-sizing: border-box;
                    position: relative;

                    @include respond-min(1280px) {
                        width: 50%;
                    }

                    img {
                        width: 1.25rem;
                        position: absolute;
                        right: 1rem;
                        top: calc(50% - 6px);
                    }
                }

                a:last-child {
                    background-color: $prime-blue;
                    border-color: $prime-blue;
                    margin-bottom: 1.5rem;

                    @include respond-min($mq-medium) {
                        background-color: $prime-blue;
                        border-color: $prime-blue;
                        //color: #ffffff;
                    }

                    img {
                        width: 1.25rem;
                        position: absolute;
                        right: 1rem;
                        top: calc(50% - 6px);
                    }
                }

                a:hover {
                    background-color: $midnight;
                    border-color: $midnight;
                    color: $white;
                }
            }

            .image-wrap {
                flex: 1 1 50%;
                width: 100%;

                @include respond-min($mq-large) {
                    flex: 0 50%;
                    min-width: 30rem;
                }

                img {
                    width: 100%;
                }
            }
        }
    }

    .how-it-works {
        display: flex;
        flex: 100%;
        align-items: center;
        justify-content: center;

        a {
            display: flex;
            float: left;
            color: #000000;
            background-color: $orange;
            border-color: $orange;
            border-radius: 0;
            font-size: 1.25rem;
            line-height: 1.125rem;
            font-weight: 700;
            text-align: left;
            text-transform: uppercase;
            text-decoration: none;
            width: 100%;
            padding: 1.5rem;
            box-sizing: border-box;
            position: relative;

            @include respond-min($mq-medium) {
                width: 50%;
            }

            &:hover {
                background-color: $midnight;
                color: $white;
            }

            img {
                width: 1.25rem;
                position: absolute;
                right: 1rem;
                top: calc(50% - 6px);
            }
        }
    }
