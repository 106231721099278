﻿.page {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 2rem;
    overflow: hidden;
    .smaller{
        max-width: 760px;
    }

    &.overflow {
        overflow: visible;
    }
}

.page-large {
    max-width: 1150px;
    margin: 0 auto;
    padding: 0 2rem;
    overflow: hidden;
}

.page-small{
    max-width: 760px;
    margin: 0 auto;
    padding: 0 2rem;
    overflow: hidden;
}

@include respond-min-max($mq-medium, $mq-large) {
    .page, .page-large, .page-small {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

@include respond-min-max($mq-small, $mq-medium) {
    .page, .page-large, .page-small {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        .smaller{
            max-width: 100%;
        }
    }
}

@include respond-max($mq-small) {
    .page, .page-large, .page-small {
        padding-left: 1rem;
        padding-right: 1rem;

        .smaller {
            max-width: 100%;
        }
    }
}

.hidden {
    display: none;
}

a {
    color: $midnight;
    text-decoration: underline;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

*:focus{
    outline: none;
}

img{
    max-width: 100%;
    height: auto;
}
