﻿.rte {

    ul, ol {
        line-height: 30px;
        margin-left: 30px;
        margin-bottom: 30px;
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }


    strong {
    }

    em {
        font-style: italic;
    }

    p img, .embeditem {
        max-width: 100% !important;
        height: auto !important;
    }
}
